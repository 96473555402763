import { DateTime } from 'luxon';

import config from '@/config';

import client, { Api } from './client';

const BASE_URL = '/users';

export default {
  me: () => client.get([BASE_URL, 'current']),
  get: userId => client.get([BASE_URL, userId]),
  getSimilarUsers: (userId, params) => client.get([BASE_URL, userId, 'similar'], params),
  getAll: params => client.get(BASE_URL, { ...params }),
  getContacts: userId => client.get([BASE_URL, userId, 'contacts']),
  getSessions: (userId, params) => client.get([BASE_URL, userId, 'sessions'], params),
  updateEmail: (userId, data) => {
    const request = client.post([BASE_URL, userId, 'email'], data);
    return Api.waitForCommand(request);
  },
  deleteAccount: userId => client.delete([BASE_URL, userId]),
  updatePassword: (userId, data) => client.post([BASE_URL, userId, 'password'], data),
  updateBillingInfo: (userId, data) => {
    const request = client.post([BASE_URL, userId, 'billingInfo'], data);
    return Api.waitForCommand(request);
  },
  updateNotifications: (_, data) => {
    const request = client.post([BASE_URL, 'settings', 'emails'], data);
    return Api.waitForCommand(request);
  },
  unsubscribeAllNotifications: () => {
    const request = client.post([BASE_URL, 'settings', 'emails', 'unsubscribe-from-all']);
    return Api.waitForCommand(request);
  },
  sendVerifySms: (userId, data) => {
    const request = client.put([BASE_URL, userId, 'settings', 'two-factor-auth', 'send-sms'], data);
    return Api.waitForCommand(request);
  },
  updatePhone: (userId, data) => {
    const request = client.put([BASE_URL, userId, 'phone'], data);
    return Api.waitForCommand(request);
  },
  verifyPhone: (userId, data) => {
    const request = client.put([BASE_URL, userId, 'phone', 'verify'], data);
    return Api.waitForCommand(request);
  },
  requestVerification: () => {
    const request = client.post([BASE_URL, 'request-verification']);
    return Api.waitForCommand(request);
  },
  getTotp: userId => client.get([BASE_URL, userId, 'settings', 'two-factor-auth', 'totp']),
  enableTwoFactorAuth: (userId, data) => {
    const request = client.put([BASE_URL, userId, 'settings', 'two-factor-auth', 'enable'], data);
    return Api.waitForCommand(request);
  },
  enableSecurityActions: (userId, data) => {
    const request = client.put([BASE_URL, userId, 'session', 'security-actions', 'enable'], data);
    return Api.waitForCommand(request);
  },
  disableTwoFactorAuth: (userId, data) => {
    const request = client.put([BASE_URL, userId, 'settings', 'two-factor-auth', 'disable'], data);
    return Api.waitForCommand(request);
  },
  showRecoveryCode: userId => client.get([BASE_URL, userId, 'settings', 'two-factor-auth', 'recovery-code']),
  updateRecoveryCode: userId => {
    const request = client.post([BASE_URL, userId, 'settings', 'two-factor-auth', 'recovery-code']);
    return Api.waitForCommand(request);
  },
  like: (userId, portfolioItemId) => {
    const request = client.post([BASE_URL, userId, 'portfolio-item', portfolioItemId, 'like']);
    return Api.waitForCommand(request);
  },
  unlike: (userId, portfolioItemId) => {
    const request = client.post([BASE_URL, userId, 'portfolio-item', portfolioItemId, 'unlike']);
    return Api.waitForCommand(request);
  },
  updateMainInfo: (userId, data) => {
    const request = client.post([BASE_URL, userId], data);
    return Api.waitForCommand(request);
  },
  updateVideo: (userId, data) => Api.waitForCommand(client.post([BASE_URL, userId, 'video'], data)),
  updateAvatar: (userId, file, dimensions) => {
    const requestPromise = client.postFile([BASE_URL, userId, 'avatar'], file, dimensions);
    return Api.waitForCommand(requestPromise);
  },
  updateBackgroundImage: (userId, file, dimensions) => {
    const requestPromise = client.postFile([BASE_URL, userId, 'backgroundImage'], file, dimensions);
    return Api.waitForCommand(requestPromise);
  },
  deleteAvatar: userId => client.delete([BASE_URL, userId, 'avatar']),
  deleteBackgroundImage: userId => client.delete([BASE_URL, userId, 'backgroundImage']),
  updateLanguages: (userId, data) => client.post([BASE_URL, userId, 'languages'], data),
  updateSkills: (userId, data) => client.post([BASE_URL, userId, 'skills'], data),
  updateCategories: (userId, data) => {
    const request = client.post([BASE_URL, userId, 'categories'], data);
    return Api.waitForCommand(request);
  },

  addEmployment: (userId, data) => client.post([BASE_URL, userId, 'employments'], data),
  updateEmployment: (userId, data, employmentId) => client.put([BASE_URL, userId, 'employments', employmentId], data),
  deleteEmployment: (userId, data, employmentId) => client.delete([BASE_URL, userId, 'employments', employmentId], data),

  addEducation: (userId, data) => client.post([BASE_URL, userId, 'educations'], data),
  updateEducation: (userId, data, educationId) => client.put([BASE_URL, userId, 'educations', educationId], data),
  deleteEducation: (userId, data, educationId) => client.delete([BASE_URL, userId, 'educations', educationId], data),

  addCertificate: (userId, data) => client.post([BASE_URL, userId, 'certificates'], data),
  updateCertificate: (userId, data, certificateId) => client.put([BASE_URL, userId, 'certificates', certificateId], data),
  deleteCertificate: (userId, data, certificateId) => client.delete([BASE_URL, userId, 'certificates', certificateId], data),

  addPatent: (userId, data) => client.post([BASE_URL, userId, 'patents'], data),
  updatePatent: (userId, data, patentId) => client.put([BASE_URL, userId, 'patents', patentId], data),
  deletePatent: (userId, data, patentId) => client.delete([BASE_URL, userId, 'patents', patentId], data),

  addPublication: (userId, data) => client.post([BASE_URL, userId, 'publications'], data),
  updatePublication: (userId, data, publicationId) => client.put([BASE_URL, userId, 'publications', publicationId], data),
  deletePublication: (userId, data, publicationId) => client.delete([BASE_URL, userId, 'publications', publicationId], data),

  addHonorAward: (userId, data) => client.post([BASE_URL, userId, 'honorAwards'], data),
  updateHonorAward: (userId, data, honorAwardId) => client.put([BASE_URL, userId, 'honorAwards', honorAwardId], data),
  deleteHonorAward: (userId, data, honorAwardId) => client.delete([BASE_URL, userId, 'honorAwards', honorAwardId], data),

  addView: data => client.post([BASE_URL, 'views'], data),
  getStatistics: data => client.get([BASE_URL, 'statistics'], data),
  getFreelancerStatistics: data => client.get([BASE_URL, 'freelancer-statistics'], data),

  addPortfolioItemView: (userId, portfolioItemId) => client.post([BASE_URL, userId, 'portfolio-item', portfolioItemId, 'view']),
  addPortfolioItemComment: (userId, portfolioItemId, data) => {
    const request = client.post([BASE_URL, userId, 'portfolio-item', portfolioItemId, 'comments'], data);
    return Api.waitForCommand(request);
  },

  flag: (userId, data) => client.post([BASE_URL, userId, 'flag'], data),
  getBlogArticles: (userId, params) => client.get([BASE_URL, userId, 'blog-articles'], params),

  getPortfolioItemComments: portfolioItemId => client.get([BASE_URL, portfolioItemId, 'comments']),
  // XXX: UserPortfolio _id property required
  savePortfolioItem: (userId, data) => {
    let request;
    if (data._id) {
      request = client.put([BASE_URL, userId, 'portfolio-item', data._id], data);
    } else {
      request = client.post([BASE_URL, userId, 'portfolio-item'], data);
    }
    return Api.waitForCommand(request);
  },
  deletePortfolioItem: (userId, portfolioItemId) => {
    const request = client.delete([BASE_URL, userId, 'portfolio-item', portfolioItemId]);
    return Api.waitForCommand(request);
  },
  orderPortfolio(userId, portfolioItemIds) {
    const request = client.put([BASE_URL, userId, 'portfolio', 'order'], { portfolioItemIds });
    return Api.waitForCommand(request);
  },
  link: (userId, provider, profileUrl) => {
    const request = client.post([BASE_URL, userId, 'social-profiles', provider], { profileUrl });
    return Api.waitForCommand(request);
  },

  unlink: (userId, provider) => {
    const request = client.delete([BASE_URL, userId, 'social-profiles', provider]);
    return Api.waitForCommand(request);
  },

  updateProfileSocialNetworks(userId, role, socialNetworks) {
    const request = client.post([BASE_URL, userId, role, 'social-networks'], { socialNetworks });
    return Api.waitForCommand(request);
  },

  updateFreelancerSocialNetworks: (userId, socialNetworks) => {
    const request = client.post([BASE_URL, userId, 'freelancer', 'social-networks'], { socialNetworks });
    return Api.waitForCommand(request);
  },

  searchGolancers: conditions => client.get([BASE_URL, 'golancers'], conditions),

  getSuggestedGolancers: conditions => client.get([BASE_URL, 'suggested'], conditions),

  updateAssessmentVisibility: (userId, data) => client.put([BASE_URL, userId, 'assessments', 'visibility'], data),

  isUserOnline(lastActivityDate) {
    const lastActivity = DateTime.fromISO(lastActivityDate);
    const secondsSinceLastActivity = DateTime.utc().diff(lastActivity, 'seconds').toObject().seconds;

    return secondsSinceLastActivity < config.secondsUntilOffline;
  },

  updateBanners(userId, data) {
    const request = client.put([BASE_URL, userId, 'banners'], data);
    return Api.waitForCommand(request);
  },

  changeOnboardingStep: (userId, data) => {
    const request = client.put([BASE_URL, userId, 'onboarding', 'change-step'], data);
    return Api.waitForCommand(request);
  },

  completeOnboarding: (userId, data) => {
    const request = client.put([BASE_URL, userId, 'onboarding', 'complete'], data);
    return Api.waitForCommand(request);
  },

  showSitejabberModal: userId => {
    const request = client.put([BASE_URL, userId, 'show-sitejabber-modal']);
    return Api.waitForCommand(request);
  },

  getNotifications(userId, conditions) {
    return client.get([BASE_URL, userId, 'notifications'], conditions);
  },
  readNotification(userId, notificationId) {
    return client.put([BASE_URL, userId, 'notifications', 'read'], { notificationId });
  },
  getAndReadNotification(userId, notificationId) {
    return client.get([BASE_URL, userId, 'notifications', notificationId]);
  },
  readAllNotifications(userId) {
    return client.post([BASE_URL, userId, 'notifications', 'readAll']);
  },

  updateEmployerHeadline: (userId, data) => {
    const request = client.post([BASE_URL, userId, 'employer', 'headline'], data);
    return Api.waitForCommand(request);
  },
  updateEmployerSummary: (userId, data) => {
    const request = client.post([BASE_URL, userId, 'employer', 'summary'], data);
    return Api.waitForCommand(request);
  },
  updateEmployerVideo: (userId, data) => {
    const request = client.post([BASE_URL, userId, 'employer', 'video'], data);
    return Api.waitForCommand(request);
  },
  updateEmployerBackgroundImage: (userId, file, dimensions) => {
    const requestPromise = client.postFile([BASE_URL, userId, 'employer', 'backgroundImage'], file, dimensions);
    return Api.waitForCommand(requestPromise);
  },
  deleteEmployerBackgroundImage: userId => client.delete([BASE_URL, userId, 'employer', 'backgroundImage']),

  updateEmployerSocialNetworks: (userId, socialNetworks) => {
    const request = client.post([BASE_URL, userId, 'employer', 'social-networks'], { socialNetworks });
    return Api.waitForCommand(request);
  },

  updateEmployerProfileTipsVisibility: (userId, isProfileTipsHidden) => client.put([BASE_URL, userId, 'employer', 'profile-tips'], { isProfileTipsHidden }),

  updateDashboardWelcomeBannerVisibility: (userId, isWelcomeBannerShown) => client.put([BASE_URL, userId, 'dashboard', 'welcome-banner'], { isWelcomeBannerShown }),

  updateDashboardOnboardingWidgetVisibility: (userId, isOnboardingWidgetActive) => client.put([BASE_URL, userId, 'dashboard-settings'], { isOnboardingWidgetActive }),

  acceptInboxWarning: userId => Api.waitForCommand(client.put([BASE_URL, userId, 'accept-inbox-warning'])),
  addFcmToken(userId, token) {
    return client.post([BASE_URL, userId, 'fcmToken'], { token });
  },

  updateInclusiveTax(userId, data) {
    const requestPromise = client.put([BASE_URL, userId, 'inclusiveTax'], data);
    return Api.waitForCommand(requestPromise);
  },

  updateTypeformSurvey(userId, data) {
    return Api.waitForCommand(client.put([BASE_URL, userId, 'typeform-survey'], data));
  },

  parseResume(userId, file) {
    const requestPromise = client.post([BASE_URL, userId, 'onboarding', 'populate-profile'], file);
    return Api.waitForCommand(requestPromise, 60000);
  },

  generateBio(userId, data) {
    return client.post([BASE_URL, userId, 'onboarding', 'generate-bio'], data);
  },
};
