import VueRouter from 'vue-router';

import routes from '@/router/routes';
import { redirectIfNotAuthorized, setPrevRoute } from '@/router/routerServices';

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(path) {
  return originalPush.call(this, path).catch(err => err);
};
VueRouter.prototype.replace = function push(path) {
  return originalReplace.call(this, path).catch(err => err);
};

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.afterEach((_, from) => {
  setPrevRoute(from);
});

router.beforeEach((to, _, next) => {
  redirectIfNotAuthorized(to, next);
});

export default router;
