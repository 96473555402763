import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import moment from 'moment-timezone';

import _forEach from 'lodash/forEach';
import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';

import config from '@/config';

import logger from '@/logger';
import userApi from '@/services/api/users';
import exchangeRatesApi from '@/services/api/exchangeRates';
import Permissions from '@/services/infrastructure/permissions';
// eslint-disable-next-line import/no-cycle
import { getActiveCompanies } from '@/services/company';
import { getUserWallets } from '@/services/wallets';
import { verificationRequestStatus } from '@/enums/users';

import dashboard from './modules/dashboard';
import hourlyContracts from './modules/hourlyContracts';

Vue.use(Vuex);

// XXX: split into separate modules when the time comes
const store = {
  state: {
    app: {
      modals: {
        invoice: {
          show: false,
          contract: null,
        },
        postJob: {
          show: false,
          job: null,
          previousJobs: null,
          activeSectionName: null,
          onClose: null,
        },
        editProfile: {
          show: false,
          user: null,
          scrollTo: null,
          view: null,
          unsavedFields: {},
          onClose: null,
        },
        milestone: {
          show: false,
          milestone: null,
          onMilestoneDelete: null,
        },
        cardBillingPaymentMethod: {
          show: false,
          companyId: null,
        },
        cardVerification: {
          show: false,
          card: null,
        },
        sendBonus: {
          show: false,
          preSelectedContract: null,
          isStripeFlow: false,
        },
        sendPayment: {
          show: false,
        },
        sendFlexiblePayment: {
          show: false,
        },
        mfaVerifyPhone: {
          show: false,
          enableTwoFactorAuth: null,
          isSetUp: false,
          title: 'Multi-Factor Authentication via SMS',
        },
        mfaVerifyTotp: {
          show: false,
          actionType: null,
          actionDescription: null,
        },
        mfaChooseType: {
          show: false,
          actionType: null,
          description: null,
        },
        mfaEnabledVerifyTotp: {
          show: false,
          onVerifySuccess: null,
          description: null,
        },
        mfaEnabledVerifySms: {
          show: false,
          onVerifySuccess: null,
          description: null,
          enableTwoFactorAuth: null,
        },
        mfaRecoveryCode: {
          show: false,
        },
        mfaResetViaRecoveryCode: {
          show: false,
        },
        videoTutorials: {
          show: false,
        },
        mfaReset: {
          show: false,
        },
        postNewJob: {
          show: false,
          actionType: null,
          actionDescription: null,
        },
        sendOffer: {
          show: false,
          service: null,
          user: null,
        },
        createOfferModal: {
          show: false,
          offerType: null,
          user: null,
          service: null,
          companyId: null,
          proposalId: null,
        },
        addManualTime: {
          show: false,
          preselectedContract: null,
          preselectedDate: null,
          timezone: null,
          successHandler: null,
        },
        createCompany: {
          show: false,
          type: null,
        },
        selectBillingPMType: {
          show: false,
          companyId: null,
          paymentMethod: null,
        },
        offer: {
          show: false,
          offerId: null,
        },
        interviewConfirmation: {
          show: false,
          payload: null,
        },
        interviewReminder: {
          show: false,
          payload: null,
          title: null,
        },
        thread: {
          show: false,
          threadId: null,
          preloadedThread: null,
        },
        addTimeOffPolicy: {
          show: false,
          payload: null,
        },
      },
      isNotificationsShown: false,
      isSitejabberFeedbackWidgetShown: false,
      screenSize: {
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: false,
      },
    },
    routing: {
      prevRoute: null,
    },
    auth: {
      user: {},
      isAuthorized: false,
    },
    directory: {
      enums: _cloneDeep(window._bootstrap?.enums),
      countries: [],
      states: {},
      timezones: [],
      languages: [],
      categories: [],
    },
    favorites: {
      user: [],
      job: [],
      company: [],
    },
    flags: {
      features: {},
    },
    exchangeRates: {
      rates: {},
      updatedOn: null,
    },
    globalSettings: {
      enableWhatsNewModal: config.enableWhatsNewModal,
      technicalIssuesBanner: config.technicalIssuesBanner,
    },
  },

  actions: {
    async fetchCurrentUser({ commit }) {
      const user = await userApi.me();
      commit('setCurrentUser', user);
      return user;
    },

    setCurrentUser({ commit }, user) {
      commit('setCurrentUser', user);
    },

    showSitejabberFeedbackWidget({ commit, getters }) {
      const { currentUser } = getters;

      if (currentUser.sitejabberModalShown === undefined || currentUser.sitejabberModalShown === true) {
        // Old users for whom sitejabberModalShown is undefined shouldn't see modal
        return;
      }

      try {
        _debounce(() => {
          commit('setIsSitejabberFeedbackWidgetShown', true);
        }, 2000)();
        userApi.showSitejabberModal(currentUser._id);
      } catch (error) {
        logger.error(error);
      }
    },

    async fetchExchangeRates({ commit }) {
      const { rates } = await exchangeRatesApi.get();
      commit('setExchangeRates', rates);
    },
  },

  mutations: {
    setModalData(state, data) {
      if (!state.app.modals[data.path]) {
        state.app.modals[data.path] = {};
      }
      state.app.modals[data.path] = { ...state.app.modals[data.path], ...data.payload };
    },
    setSelectBillingPMTypeModalData(state, payload) {
      if (!state.app.modals.selectBillingPMType) {
        state.app.modals.selectBillingPMType = {};
      }
      state.app.modals.selectBillingPMType = { ...state.app.modals.selectBillingPMType, ...payload };
    },
    setOfferModalData(state, payload) {
      if (!state.app.modals.offer) {
        state.app.modals.offer = {};
      }
      state.app.modals.offer = { ...state.app.modals.offer, ...payload };
    },
    setScreenSize(state, screenSize) {
      state.app.screenSize = screenSize;
    },
    setPrevRoute(state, route) {
      state.routing.prevRoute = route;
    },
    setCurrentUser(state, user) {
      state.auth = {
        ...state.auth,
        user: user
          ? {
            ...user,
            wallets: getUserWallets(user),
            activeCompanies: getActiveCompanies(user.companies),
          }
          : null,
        isAuthorized: !!user,
      };
    },
    updateCurrentUser(state, data) {
      const newUserData = { ...state.auth.user, ...data };
      newUserData.wallets = getUserWallets(newUserData);
      state.auth.user = newUserData;
    },
    setEnums(state, enums) {
      state.directory.enums = enums;
    },
    setCountries(state, countries) {
      state.directory.countries = countries;
    },
    setStates(state, states) {
      state.directory.states = { ...state.directory.states, ...states };
    },
    setTimezones(state, timezones) {
      state.directory.timezones = timezones;
    },
    setLanguages(state, languages) {
      state.directory.languages = languages;
    },
    setCategories(state, categories) {
      state.directory.categories = categories;
    },
    setFavorites(state, favoritesData) {
      _forEach(favoritesData, (value, key) => {
        state.favorites[key] = value;
      });
    },
    setProfileVisibility(state, profileVisibility) {
      if (state.auth.user) {
        state.auth.user.profilePrivacy = profileVisibility;
      }
    },
    setIsSitejabberFeedbackWidgetShown(state, isShown = true) {
      state.app.isSitejabberFeedbackWidgetShown = isShown;
    },
    setNotificationsVisibility(state, isShown) {
      state.app.isNotificationsShown = isShown;
    },
    setExchangeRates(state, rates) {
      state.exchangeRates = {
        rates,
        updatedOn: new Date(),
      };
    },
  },

  getters: {
    currentUser: ({ auth }) => auth.user,
    permissions: ({ auth }) => new Permissions(auth.user),

    isAuthorized: ({ auth }) => auth.isAuthorized,
    isUnderVerification: ({ auth }) => Boolean(auth.user?.verification?._id && auth.user?.verification?.reason !== 'user_request'),
    isUnderLimitedVerification: ({ auth }) => Boolean(auth.user?.verification?._id && auth.user?.verification?.reason === 'user_request'),
    isVerified: ({ auth }) => Boolean(auth.user?.verification?.status === verificationRequestStatus.verified || auth.user?.settings?.skipVerificationCheck),
    isCurrentUser:
      ({ auth }) => user => (auth.user && auth.user._id) === user._id,

    enums: ({ directory }) => directory.enums,

    invoiceModal: ({ app }) => app.modals.invoice,
    editProfileModal: ({ app }) => app.modals.editProfile,
    milestoneModal: ({ app }) => app.modals.milestone,
    cardBillingPaymentMethodModal: ({ app }) => app.modals.cardBillingPaymentMethod,
    cardVerificationModal: ({ app }) => app.modals.cardVerification,
    sendBonusModal: ({ app }) => app.modals.sendBonus,
    sendPaymentModal: ({ app }) => app.modals.sendPayment,
    sendFlexiblePaymentModal: ({ app }) => app.modals.sendFlexiblePayment,
    mfaVerifyPhoneModal: ({ app }) => app.modals.mfaVerifyPhone,
    mfaVerifyTotpModal: ({ app }) => app.modals.mfaVerifyTotp,
    mfaChooseTypeModal: ({ app }) => app.modals.mfaChooseType,
    mfaEnabledVerifyTotpModal: ({ app }) => app.modals.mfaEnabledVerifyTotp,
    mfaEnabledVerifySmsModal: ({ app }) => app.modals.mfaEnabledVerifySms,
    mfaRecoveryCodeModal: ({ app }) => app.modals.mfaRecoveryCode,
    mfaResetViaRecoveryCodeModal: ({ app }) => app.modals.mfaResetViaRecoveryCode,
    mfaResetModal: ({ app }) => app.modals.mfaReset,
    postNewJobModal: ({ app }) => app.modals.postNewJob,
    videoTutorialsModal: ({ app }) => app.modals.videoTutorials,
    addManualTimeModal: ({ app }) => app.modals.addManualTime,
    createCompanyModal: ({ app }) => app.modals.createCompany,
    selectBillingPMTypeModal: ({ app }) => app.modals.selectBillingPMType,
    sendOfferModal: ({ app }) => app.modals.sendOffer,
    createOfferModal: ({ app }) => app.modals.createOfferModal,
    offerModal: ({ app }) => app.modals.offer,
    interviewConfirmationModal: ({ app }) => app.modals.interviewConfirmation,
    interviewReminderModal: ({ app }) => app.modals.interviewReminder,
    threadModal: ({ app }) => app.modals.thread,
    addTimeOffPolicy: ({ app }) => app.modals.addTimeOffPolicy,

    isSitejabberFeedbackWidgetShown: ({ app }) => app.isSitejabberFeedbackWidgetShown,
    screenSize: ({ app }) => app.screenSize,
    isNotificationsShown: ({ app }) => app.isNotificationsShown,

    routing: ({ routing }) => routing,
    localTimezone: () => moment.tz.guess(),
    currentTimezone: ({ auth }) => _get(auth, 'user.location.timezone.name') || moment.tz.guess() || 'Etc/UTC',

    featureFlags: ({ flags }) => flags.features,

    exchangeRates: ({ exchangeRates }) => exchangeRates.rates,
    globalSettings: ({ globalSettings }) => globalSettings,
  },

  modules: {
    dashboard,
    hourlyContracts,
  },
};

const appStore = new Store(store);

window.$store = appStore;

export default appStore;
